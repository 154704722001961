import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getDiscountsForPartner } from "./api";
import Discount from "./Discount";
import "./DiscountsTab.scss";
import ScrollToTopOnMount from "./ScrollToTopOnMount";

const DiscountsTab = ({ id, actionId }) => {
  const [discounts, setDiscounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setLoading(true);
    getDiscountsForPartner(id)
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        setDiscounts(res);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, [id]);

  let discountsToRender = actionId
    ? [
        ...discounts.filter((d) => d.id === actionId),
        ...discounts.filter((d) => d.id !== actionId),
      ]
    : discounts;

  return (
    <>
      {!location.pathname.startsWith("/partneri/") && <ScrollToTopOnMount />}
      {loading ? (
        <div className="loading">Учитава се...</div>
      ) : (
        <div className="discounts">
          {discountsToRender.map((discount) => (
            <Discount key={discount.id} {...discount} />
          ))}
        </div>
      )}
    </>
  );
};

export default DiscountsTab;
