import { useState, useEffect } from "react";
import { getCategoryById, getPartnerByCategory } from "./api";
import PartnerCard from "./PartnerCard";
import { Link } from "react-router-dom";
import "./PartnerList.scss";

const PartnersByCategory = ({ match }) => {
  const [category, setCategory] = useState([]);
  const [partnersByCategory, setPartnersByCategory] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id } = match.params;

  useEffect(() => {
    setLoading(true);
    getCategoryById(id)
      .then((res) => res.json())
      .then((res) => {
        setLoading(true);
        setCategory(res);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, [id]);

  useEffect(() => {
    getPartnerByCategory(id)
      .then((res) => res.json())
      .then((res) => {
        setPartnersByCategory(res);
      });
  }, [id]);

  return (
    <div className="container">
      <h2 className="title-main title-padding">{category.name}</h2>
      <div className="partner-list">
        {loading ? (
          <div className="loading">Учитава се...</div>
        ) : (
          partnersByCategory.map((partner) => (
            <Link
              key={partner.id}
              to={`${match.url}/${partner.id}`}
              className="partner"
            >
              <PartnerCard partner={partner} />
            </Link>
          ))
        )}
      </div>
    </div>
  );
};

export default PartnersByCategory;
