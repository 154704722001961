import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import usePartners from "./hooks/usePartners";
import PartnerCard from "./PartnerCard";
import { transliterateToLat } from "./transliterate";
import "./PartnerList.scss";

const PartnerList = () => {
  const initalNumberPerPage = 12;
  const { partners, loading } = usePartners();
  const [query, setQuery] = useState("");
  const [numberPerPage, setNumberPerPage] = useState(initalNumberPerPage);
  const [searchResults, setSearchResults] = useState([]);
  const match = useRouteMatch();

  const hasMore = partners.length > searchResults.length;

  const observer = useRef();
  const inputRef = useRef();
  const lastPartnerElementRef = useCallback(
    (node) => {
      if (loading) {
        return;
      }
      if (observer.current) {
        observer.current.disconnect();
      }
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setNumberPerPage((prev) => prev + initalNumberPerPage);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  useEffect(() => {
    if (!loading) {
      const results = partners
        .filter((partner) => {
          console.log(partner);
          return (
            partner.name.toLowerCase().includes(query.toLowerCase()) ||
            transliterateToLat(partner.name.toLowerCase()).includes(
              transliterateToLat(query.toLowerCase())
            )
          );
        })
        .slice(0, numberPerPage);
      setSearchResults(results);
    }
  }, [query, partners, loading, numberPerPage]);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const handleSearch = (e) => {
    setQuery(e.target.value);
  };

  return (
    <div className="container">
      <div className="partner__header">
        <h2 className="title-main">Партнери</h2>
        <input
          ref={inputRef}
          type="text"
          onChange={handleSearch}
          placeholder="Претражи"
          className="partner__search"
        />
      </div>
      <div className="partner-list">
        {searchResults.map((partner, index) => {
          if (searchResults.length === index + 1) {
            return (
              <Link
                ref={lastPartnerElementRef}
                to={`${match.url}/${partner.id}`}
                key={partner.id}
                className="partner"
              >
                <PartnerCard partner={partner} />
              </Link>
            );
          }
          return (
            <Link
              to={`${match.url}/${partner.id}`}
              key={partner.id}
              className="partner"
            >
              <PartnerCard partner={partner} />
            </Link>
          );
        })}
      </div>
      {loading && <div className="loading">Учитава се...</div>}
    </div>
  );
};

export default PartnerList;
