import "./PageDefault.scss";
import "./MobileApplication.scss";
import ScrollToTopOnMount from "../ScrollToTopOnMount";

const MobileApplication = () => {
  return (
    <div className="container">
      <ScrollToTopOnMount />
      <h3 className="hero__subtitle">Мобилна апликација</h3>
      <h1 className="hero__title">Наш херој</h1>
      <div className="content">
        <p className="text-padding">
          Апликација за мобилне уређаје Вам омогућава брз и једноставан преглед
          комплетне понуде свих партнера програма „За наше хероје”.
          <br />
          Апликација садржи информације о свим попустима, погодностима и
          промоцијама намењеним Вама и Вашој породици.
        </p>
        <p className="text-padding">
          Мобилна апликација намењена је свим корисницима персонализоване
          картице „Наш херој”.
        </p>
        <p className="text-padding">
          Преузмите бесплатно апликацију ”Наш херој” на Google Play, App Store и
          Huawei AppGallery. По завршетку инсталације, покрените апликацију и
          извршите <strong>пријављивање</strong>.
        </p>
        <ul className="list text-padding">
          <li>
            У поље корисничког имена унесите{" "}
            <strong>адресу електронске поште</strong> коју сте уписали на вашем
            захтеву за издавање картице.
          </li>
          <li>
            У поље лозинка унесите последњих <strong>шест цифара</strong> вашег
            идентификационог броја са картице.
          </li>
        </ul>
        <p className="text-padding">
          Картица Наш херој активна је од самог уручивања и није је потребно
          додатно активирати. Мобилна аликација не служи за активацију картице,
          већ за преглед партнера и погодности које картица пружа.
        </p>
        <div className="mobile-application">
          <h3 className="mobile-application__heading">
            Како да преузимате апликацију
          </h3>
          <div className="mobile-application__card-group">
            <a
              href="https://play.google.com/store/apps/details?id=rs.gov.zanaseherojeapp"
              target="_blank rel="
              rel="noreferrer"
              className="mobile-application__card"
            >
              <div>
                <h4 className="mobile-application__type">Google Play</h4>
                <ul className="mobile-application__list">
                  <li className="mobile-application__icon">
                    <span className="mobile-application__icon-android">
                      Отворите Google Play
                    </span>
                  </li>
                  <li>
                    Пронађите апликацију <strong>„Наш херој”</strong>.
                  </li>
                  <li>
                    Када одаберете апликацију, притисните{" "}
                    <strong>Инсталирај</strong>.
                  </li>
                  <li>
                    <strong>Отворите апликацију</strong> (ако све ради, готово
                    је!)
                  </li>
                </ul>
                <p>
                  <strong>* Aко имате проблем са покретањем апликације</strong>{" "}
                  (што не би требало да се деси), активирајте опцију „Непознати
                  извори“ у безбедносним поставкама.
                </p>
              </div>
            </a>
            <a
              href="https://apps.apple.com/rs/app/%D0%BD%D0%B0%D1%88-%D1%85%D0%B5%D1%80%D0%BE%D1%98/id1574100733"
              target="_blank rel="
              rel="noreferrer"
              className="mobile-application__card"
            >
              <h4 className="mobile-application__type">App Store</h4>
              <ul className="mobile-application__list">
                <li className="mobile-application__icon">
                  <span className="mobile-application__icon-ios">
                    Отворите App Store
                  </span>
                </li>
                <li>
                  Пронађите апликацију <strong>„Наш херој”</strong>.
                </li>
                <li>
                  Када одаберете апликацију, притисните{" "}
                  <strong>Инсталирај</strong>.
                </li>
                <li>
                  <strong>Отворите апликацију</strong> (ако све ради, готово
                  је!)
                </li>
              </ul>
              <p>
                <strong>* Aко имате проблем са покретањем апликације</strong>{" "}
                (што не би требало да се деси), активирајте опцију „Непознати
                извори“ у безбедносним поставкама.
              </p>
            </a>
            <a
              href="https://appgallery.huawei.com/#/app/C103901137"
              target="_blank rel="
              rel="noreferrer"
            >
              <div className="mobile-application__card">
                <h4 className="mobile-application__type">Huawei App Gallery</h4>
                <ul className="mobile-application__list">
                  <li className="mobile-application__icon">
                    <span className="mobile-application__icon-huawei">
                      Отворите Huawei App Gallery
                    </span>
                  </li>
                  <li>
                    Пронађите апликацију <strong>„Наш херој”</strong>.
                  </li>
                  <li>
                    Када одаберете апликацију, притисните{" "}
                    <strong>Инсталирај</strong>.
                  </li>
                  <li>
                    <strong>Отворите апликацију</strong> (ако све ради, готово
                    је!)
                  </li>
                </ul>
                <p>
                  <strong>* Aко имате проблем са покретањем апликације</strong>{" "}
                  (што не би требало да се деси), пробајте инсталацију преко
                  Petal Search апликације.
                </p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileApplication;
