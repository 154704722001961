import "./ForOurHeroes.scss";
import "../../pages/PageDefault.scss";
import nurse from "../../img/pogodnosti-back.jpg";

const ForOurHeroes = () => (
  <div>
    <div className="nurse">
      <div className="nurse__image">
        <img src={nurse} alt="Сестра" />
      </div>
      <div className="nurse__text-content">
        <h2 className="title-main title-padding">Погодности картице</h2>
        <ul className="nurse__list text-padding">
          <li className="nurse__list-item">
            Попусти, односно погодности приликом плаћања роба и услуга код
            компанија и предузетника које партнерски учествују у програму
          </li>
          <li className="nurse__list-item">
            Предност приликом остваривања права и добијања услуга у државним
            органима, установама, јавним предузећима, као и привредним друштвима
            која обављају делатност од општег интереса
          </li>
          <li className="nurse__list-item">
            Брже остваривање права грађана у локалним самоуправама
          </li>
          <li className="nurse__list-item">
            Приоритетна доступност предшколских установа у условима њиховог
            ограниченог рада
          </li>
          <li className="nurse__list-item">
            Приоритетна доступност продуженог боравка за ученике у основној
            школи у условима њиховог ограниченог рада
          </li>
          <li className="nurse__list-item">
            Приоритет у добијању услуга установа социјалне заштите за чланове
            породице
          </li>
        </ul>
      </div>
    </div>
  </div>
);

export default ForOurHeroes;
