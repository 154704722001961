import { baseUrl } from "./api";
import "./BenefitsTab.scss";
import notAvailable from "./img/not_available.png";

const BenefitsTab = ({ partner }) => {
  const { id, benefits, benefitsImage } = partner;
  return (
    <>
      <p
        dangerouslySetInnerHTML={{ __html: benefits }}
        className="benefits-text"
      />
      {benefitsImage && (
        <img
          src={`${baseUrl}/Partner/${id}/img/3/${benefitsImage}`}
          alt="Погодности"
          className="benefits-image"
        />
      )}
    </>
  );
};

export default BenefitsTab;
