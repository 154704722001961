import { BiPhone, BiHome, BiHourglass, BiLinkAlt, BiAt } from "react-icons/bi";

import "./Location.scss";

const Location = ({
  name,
  address,
  city,
  hours,
  phoneNumber,
  email,
  webAddress,
  locationX: long,
  locationY: lat,
  zoom,
}) => {
  let iconStyle = {
    size: 20,
    color: "#03497e",
  };

  return (
    <div className="location">
      <h3 className="location__name">{name}</h3>
      {(address || city) && (
        <div className="location__data">
          <BiHome
            className="location__data-icon"
            size={iconStyle.size}
            color={iconStyle.color}
          />
          <p className="location__data-address">
            {address} {city}
          </p>
        </div>
      )}
      {webAddress && (
        <div className="location__data">
          <BiLinkAlt
            className="location__data-icon"
            size={iconStyle.size}
            color={iconStyle.color}
          />{" "}
          <a href={`${webAddress}`} target="_blank" rel="noopener noreferrer">
            {webAddress}
          </a>
        </div>
      )}
      {email && (
        <div className="location__data">
          <BiAt
            className="location__data-icon"
            size={iconStyle.size}
            color={iconStyle.color}
          />{" "}
          <a href={`mailto:${email}`}>{email}</a>
        </div>
      )}
      {phoneNumber && (
        <div className="location__data">
          <BiPhone
            className="location__data-icon"
            size={iconStyle.size}
            color={iconStyle.color}
          />
          <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
        </div>
      )}
      {hours && (
        <div className="location__data">
          <BiHourglass
            className="location__data-icon"
            size={iconStyle.size}
            color={iconStyle.color}
          />
          <p className="location__data-address">{hours}</p>
        </div>
      )}
      {long !== 0 && lat !== 0 ? (
        <a
          href={`http://maps.google.com/?q=${long},${lat}&zoom=${zoom}`}
          target="_blank"
          rel="noopener noreferrer"
          className="location__button"
        >
          Прикажи на мапи
        </a>
      ) : null}
    </div>
  );
};

export default Location;
