import ScrollToTopOnMount from "../ScrollToTopOnMount";
import "./PageDefault.scss";
import pdf from "../assets/Uslovi koriscenja kartice pogodnosti Nas heroj.pdf";

const BenefitCard = () => {
  return (
    <div className="container">
      <ScrollToTopOnMount />
      <h3 className="hero__subtitle">Картица погодности</h3>
      <h1 className="hero__title">Наш херој</h1>
      <div className="content">
        <p className="text-padding">
          Програм подршке „За наше хероје“ кроз персонализоване картице
          погодности „Наш херој“, покренулa je Влада Републике Србије („Службени
          гласник РС”, број 146/20; 05 број 33-9862/2020-1). Програм подршке „За
          наше хероје“ реализује Министарство здравља РС у сарадњи са Генералним
          секретаријатом Владе, Народном банком Србије, Привредном комором
          Србије и јавним установама, уз подршку Програма Уједињених нација за
          развој (УНДП).Мере и активности везане за картицу погодности „Наш
          херој“ биће привременог карактера, односно орочене су трајањем
          пандемије болести COVID-19 изазване вирусом SARS-CoV-2.
        </p>
        <p className="text-padding">
          Картица погодности „Наш херој“ je персонализована картица која
          омогућава одговарајуће погодности приликом плаћања роба и услуга код
          привредних друштава, правних лица или предузетника, односно установа
          на територији Републике Србије са којима je закључeн Протокол о
          сарадњи.
        </p>
        <p className="text-padding">
          Корисник картице може бити запослени, лице ангажовано по другом основу
          или волонтер у установама здравствене и социјалне заштите у јавној
          својини Републике Србије и објектима који су посебно одређени и
          припремљени за изолацију и лечење оболелих, односно заражених вирусом
          SARS-CoV-2 који непосредно пружа здравствену заштиту, односно спроводи
          мере и активности за лечење, здравствену негу и рехабилитацију
          пацијената оболелих, односно заражених вирусом SARS-CoV-2 и спроводи
          друге мере и активности ради спречавања појаве, ширења и сузбијања
          заразне болести COVID-19.
        </p>
        <ul className="list text-padding">
          <li>
            Картица се издаје на основу Захтева за добијање Картице „Наш херој“.
          </li>
          <li>
            Захтев за издавање картице корисник подноси лично овлашћеној
            административној служби у својој локалној COVID-19 јединици.
          </li>
          <li>
            По подношењу захтева, врши се израда картица. Свака картица је
            персонализована, односно садржи свој идентификациони (ИД) број који
            не открива ни један податак о личности. Након персонализације
            картице ИД број постаје активан, а самим тим корисник стиче право за
            активацију и коришћење картице.
          </li>
          <li>
            Персонализовану картицу корисник преузима у овлашћеној
            административној служби у својој COVID-19 јединици.
          </li>
          <li>
            По добијању картице, корисник има могућност да се региструје на овом
            сајту и/или преузме апликацију за мобилни уређај, преко које се
            информише о погодностима које она обезбеђује.
          </li>
          <li>
            Апликација „Наш херој“ доступна је на Google Play и Huawei
            AppGallery сервисима, а ускоро ће бити доступна и на Apple AppStore
            сервису.
          </li>
          <li>
            При остваривању права на погодности, корисник је обавезан да покаже
            картицу „Наш херој“, или дигитални еквивалент / на мобилном уређају,
            као и свој идентификациони документ.
          </li>
          <li>
            Картица „Наш херој“ је персонализована и не може се пренети нити на
            било који други начин уступити на коришћење другом лицу.
          </li>
          <li>
            Картица погодности „Наш херој“ се користи на целој територији
            Републике Србије, односно није условљена местом пребивалиштава
            корисника картице.
          </li>
        </ul>
        <p className="text-padding">
          Мере и активности везане за картицу погодности „Наш херој“ су
          привремене и орочене трајањем пандемије болести COVID-19 изазване
          вирусом SARS-CoV-2.
        </p>

        <div className="download">
          <a href={pdf} className="download__link">
            Детаљније о Условима коришћења картице погодности
          </a>
        </div>
      </div>
    </div>
  );
};

export default BenefitCard;
