import "./HeroCard.scss";
import "../../pages/PageDefault.scss";
import zahtevPdf from "../../assets/Zahtev za dobijanje kartice pogodnosti Nas heroj.pdf";
import usloviPdf from "../../assets/Uslovi koriscenja kartice pogodnosti Nas heroj.pdf";
import obavestenjePdf from "../../assets/Obavestenje o obradi podataka o licnosti.pdf";

import kartica from "../../img/card.png";

const HeroCard = () => (
  <div className="hero-card">
    <h2 className="title-main title-padding">Картица погодности „Наш херој“</h2>
    <div className="hero-card__content">
      <div className="hero-card__image">
        <img src={kartica} alt="Картица" />
      </div>
      <div className="hero-card__text">
        <p className="text-padding">
          Картица погодности „Наш Херој“ додељује се запосленима, радно
          ангажованима и волонтерима у COVID-19 систему, како би остварили
          попусте приликом плаћања робе и услуга код компанија и предузетника
          који су партнери у програму. Поред тога, носиоци картице имаће
          предност при остваривању права и добијању услуга у државним
          институцијама, јавним предузећима, локалним самоуправама и установама
          социјалне заштите.
        </p>
        <div className="links-conainter">
          <div className="download download__padded download-colored">
            <a href={zahtevPdf} className="download__link">
              Захтев за добијање картице погодности
            </a>
          </div>

          <div className="download download__padded download-colored">
            <a href={usloviPdf} className="download__link">
              Услови коришћења картице погодности
            </a>
          </div>
          <div className="download download__padded download-colored">
            <a href={obavestenjePdf} className="download__link">
              Обавештење о обради података о личности
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default HeroCard;
