import { useEffect } from "react";

const Logout = () => {
  useEffect(() => {
    localStorage.removeItem("heroUser");

    window.location = "/";
  }, []);

  return null;
};

export default Logout;
