import { useState } from "react";
import { Route, Redirect, useHistory, Switch } from "react-router-dom";
import Sidebar from "./Sidebar";
import WelcomePage from "./pages/WelcomePage";
import ApplyForCard from "./pages/ApplyForCard";
import BecomePartner from "./pages/BecomePartner";
import BenefitCard from "./pages/BenefitCard";
import MobileApplication from "./pages/MobileApplication";
import PartnerList from "./PartnerList";
import ActionList from "./ActionList";
import Contact from "./Contact";
import Login from "./Login";
import Logout from "./Logout";
import Footer from "./Footer";
import Profile from "./Profile";
import Partner from "./Partner";
import PartnersByCategory from "./PartnersByCategory";
import ForgottenPassword from "./ForgottenPassword";
import Category from "./Category";
import { login } from "./api";
import useLocalStorage from "./hooks/useLocalStorage";
import { getCurrentUser, KEY } from "./auth";
import "./App.scss";
import ScrollTopArrow from "./ScrollToTopArrow";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Faq from "./pages/Faq";

function App() {
  const [user, setUser] = useLocalStorage(KEY, getCurrentUser());
  const [error, setError] = useState(null);

  const history = useHistory();

  const handleLogin = (username, password) => {
    if (username.length === 0 || password.length === 0) {
      setError("Морате унети корисничку адресу и лозинку");
      return;
    }
    login(username, password)
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          setError("Погрешни креденцијали");
          throw Error(`Request rejected with status ${res.status}`);
        }
      })
      .then((res) => {
        setUser(res);
        history.replace("/partneri");
      })
      .catch((err) => console.log("err", err));
  };

  const handleLogout = () => {
    console.log("logging out");
    setUser(null);
    localStorage.removeItem(KEY);
  };

  return (
    <>
      <ScrollTopArrow />
      <Sidebar user={user} logout={handleLogout} />
      <div className="main-content">
        <Switch>
          <Route
            path="/"
            exact
            render={(props) => {
              return <WelcomePage {...props} user={user} />;
            }}
          />
          <Route path="/kartica-pogodnosti" component={BenefitCard} />
          <Route path="/prijavite-se" component={ApplyForCard} />
          <Route path="/postanite-partner" exact component={BecomePartner} />
          <Route path="/mobilnа-aplikacijа" component={MobileApplication} />
          <Route path="/faq" component={Faq} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route
            exact
            path="/partneri"
            render={(props) => {
              if (!user) return <Redirect to="/" />;
              return <PartnerList {...props} />;
            }}
          />
          <Route
            path="/roba"
            exact
            render={(props) => {
              if (!user) return <Redirect to="/" />;
              return <Category {...props} id="2" name="Роба" />;
            }}
          />
          <Route
            path="/usluge"
            exact
            render={(props) => {
              if (!user) return <Redirect to="/" />;
              return <Category {...props} id="1" name="Услуге" />;
            }}
          />
          <Route
            path="/javne-usluge"
            exact
            render={(props) => {
              if (!user) return <Redirect to="/" />;
              return <Category {...props} id="3" name="Јавне услуге" />;
            }}
          />
          <Route
            path="/popusti"
            render={(props) => {
              if (!user) return <Redirect to="/" />;
              return <ActionList {...props} />;
            }}
          />
          <Route
            path="/kontakt"
            render={(props) => {
              if (!user) return <Redirect to="/" />;
              return <Contact {...props} />;
            }}
          />
          <Route
            path="/profil"
            render={(props) => {
              if (!user) return <Redirect to="/" />;
              return <Profile {...props} />;
            }}
          />
          <Route path="/logout" component={Logout} />
          <Route
            path="/login"
            render={(props) => {
              if (user) return <Redirect to="/" />;
              return (
                <Login
                  {...props}
                  onLogin={handleLogin}
                  error={error}
                  setError={setError}
                />
              );
            }}
          />
          <Route
            path="/zaboravljena-lozinka"
            render={(props) => {
              if (user) return <Redirect to="/" />;
              return <ForgottenPassword {...props} />;
            }}
          />
          <Route
            path="/roba/:id"
            exact
            render={(props) => {
              if (!user) return <Redirect to="/" />;
              return <PartnersByCategory {...props} />;
            }}
          />
          <Route
            path="/roba/:id/:id"
            render={(props) => {
              if (!user) return <Redirect to="/" />;
              return <Partner {...props} />;
            }}
          />
          <Route
            path="/usluge/:id"
            exact
            render={(props) => {
              if (!user) return <Redirect to="/" />;
              return <PartnersByCategory {...props} />;
            }}
          />
          <Route
            path="/usluge/:id/:id"
            render={(props) => {
              if (!user) return <Redirect to="/" />;
              return <Partner {...props} />;
            }}
          />
          <Route
            path="/javne-usluge/:id"
            exact
            render={(props) => {
              if (!user) return <Redirect to="/" />;
              return <PartnersByCategory {...props} />;
            }}
          />
          <Route
            path="/javne-usluge/:id/:id"
            render={(props) => {
              if (!user) return <Redirect to="/" />;
              return <Partner {...props} />;
            }}
          />
          <Route
            path="/partneri/:id"
            render={(props) => {
              if (!user) return <Redirect to="/" />;
              return <Partner {...props} />;
            }}
          />
        </Switch>
        <Footer />
      </div>
    </>
  );
}

export default App;
