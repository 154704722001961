import React, { useState, useEffect, useRef, useCallback } from "react";
import { getRandomPartners } from "../../api";
import PartnerCard from "../../PartnerCard";
import styles from "./RandomPartners.module.scss";
import usePartners from "../../hooks/usePartners";
import { Link, useRouteMatch } from "react-router-dom";

const RandomPartners = ({ user }) => {
  const initalNumberPerPage = 12;
  const { partners, loading } = usePartners();
  const [numberPerPage, setNumberPerPage] = useState(initalNumberPerPage);
  const [partnersToDisplay, setPartnersToDisplay] = useState([]);

  const match = useRouteMatch();
  console.log(match);

  const observer = useRef();
  const lastPartnerElementRef = useCallback(
    (node) => {
      if (loading) {
        return;
      }
      if (observer.current) {
        observer.current.disconnect();
      }
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setNumberPerPage((prev) => prev + initalNumberPerPage);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading]
  );

  useEffect(() => {
    if (!loading) {
      const results = partners.slice(0, numberPerPage);
      setPartnersToDisplay(results);
    }
  }, [partners, loading, numberPerPage]);

  return (
    <div className={styles.container}>
      <h2 className="title-main title-padding">Наши партнери</h2>
      <div className={styles["randompartner-list"]}>
        {loading ? (
          <div className="loading">Учитава се...</div>
        ) : (
          partnersToDisplay.map((partner, index) => {
            if (partnersToDisplay.length === index + 1) {
              return !user ? (
                <div
                  ref={lastPartnerElementRef}
                  className={styles.partner}
                  key={partner.id}
                >
                  <PartnerCard partner={partner} />
                </div>
              ) : (
                <Link
                  ref={lastPartnerElementRef}
                  to={`/partneri/${partner.id}`}
                  key={partner.id}
                  className="partner"
                >
                  <PartnerCard partner={partner} />
                </Link>
              );
            }
            return !user ? (
              <div className={styles.partner} key={partner.id}>
                <PartnerCard partner={partner} />
              </div>
            ) : (
              <Link
                to={`partneri/${partner.id}`}
                key={partner.id}
                className="partner"
              >
                <PartnerCard partner={partner} />
              </Link>
            );
          })
        )}
      </div>
      {loading && <div className="loading">Учитава се...</div>}
    </div>
  );
};

export default RandomPartners;
