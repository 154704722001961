export const KEY = "heroUser";

export const getCurrentUser = () => {
  const json = localStorage.getItem(KEY);
  let user;
  try {
    user = JSON.parse(json);
  } catch (error) {
    localStorage.removeItem(KEY);
  }
  return user;
};
