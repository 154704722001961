import ScrollToTopOnMount from "../ScrollToTopOnMount";
import "./PageDefault.scss";

const PrivacyPolicy = () => {
  return (
    <div className="container">
      <ScrollToTopOnMount />
      <h1 className="page__title title-padding">Политика приватности</h1>
      <div className="content">
        <p className="text-padding">
          Генерални секретаријат Владе Републике Србије направио је апликацију
          под називом ”Наш херој” као апликацију за слободно преузимање.
          Генерални секретаријат Владе коришћење ове Апликације омогућио је без
          наплате. Овај документ (Политика приватности) служи за информисање
          посетилаца са Условима коришћења.
        </p>
        <p className="text-padding">
          <strong>Контакт информације и руковалац подацима</strong>
        </p>
        <p className="text-padding email">
          Руковалац ће бити одговорно или овлашћено лице Генералног
          секретаријата Владе. Kонтакт подаци су e-mail:{" "}
          <a href="mailto:zastitapodatakaolicnosti@nasheroj.gov.rs">
            zastitapodatakaolicnosti@nasheroj.gov.rs
          </a>{" "}
          и телефон број: 0800252252.
        </p>
        <p className="text-padding">
          <strong>Које податке Апликација добија и како их користи?</strong>
        </p>
        <p className="text-padding">
          Апликација добија име и презиме, адресу електронске поште, број
          мобилног телефона, идентификациони број картице, а све у сврху
          добијања Картице погодности „Наш херој” и евидентирања корисника
          Картице погодности.
        </p>
        <p className="text-padding">
          <strong>Која је сврха обраде?</strong>
        </p>
        <p className="text-padding">
          Подаци који се тичу корисника прикупљају се у сврху креирањa
          јединствене базе корисника картице из које ће бити могуће проверити
          валидност издате картице.
        </p>
        <p className="text-padding">
          <strong>
            Да ли трећа лица виде и/или имају приступ подацима које Апликација
            добија?
          </strong>
        </p>
        <p className="text-padding">
          Апликација не прикупља никакве податке који би могли бити изнајмљени и
          продати трећим лицима. Идентификациони број картице ће се доставити
          партнерским компанијама без других података о личности.
        </p>
        <p className="text-padding">
          <strong>
            Да ли Апликација прикупља прецизне информације о локацији уређаја у
            реалном времену?
          </strong>
        </p>
        <p className="text-padding">
          Ова апликација не прикупља прецизне информације о локацији Вашег
          мобилног уређаја.
        </p>
        <p className="text-padding">
          <strong>Да ли апликација користи слике/медије/датотеке?</strong>
        </p>
        <p className="text-padding">
          Апликација не користи датотеке на уређају, попут слика, видео или
          аудио снимака, екстерну меморију уређаја. Апликација користи камеру
          искључиво у сврху очитавања бар кода са Картице погодности ”Наш
          херој”.
        </p>
        <p className="text-padding">
          <strong>Која права има корисник?</strong>
        </p>
        <p className="text-padding">
          Да би остварио права по основу Картице, Корисник је обавезан да исту
          приложи односно покаже на увид партнеру код којег остварује погодност,
          као и свој идентификациони документ. За остваривање права по основу
          Картице прихватаће се и дигитални еквивалент Картице, који се налази
          на апликацији „Наш херој”.
          <br />
          Корисник је дужан да о свакој промени података наведених у Захтеву
          одмах, односно без одлагања обавести установу као и одговорно лице из
          тачке 5. Услова коришћења. Корисник има право на увид, исправку и
          брисање података у било ком тренутку.
          <br />
          Корисник има право да у било ком тренутку, писменим путем откаже
          коришћење Картице, уз враћање Картице. У случају опозива пристанка за
          обраду података о личности у сврху добијања Картице, обавезно је
          враћање Картице.
          <br />
          Картица престаје да важи од тренутка пријема обавештења о отказивању
          Картице, престанком радног односа, односно након пријема опозива
          пристанка за обраду података о личности у сврху добијања Картице.
          <br />
          Корисник не може Картицу пренети нити на било који други начин
          уступити на коришћење другом лицу. Свако неовлашћено копирање и
          умножавање Картице биће санкционисано у складу са законом.
          <br />У случају губитка или крађе Картице, Корисник обавештава
          установу односно одговорно лице из тачке 5. Услова коришћења.
          <br />
          <br />У било ком тренутку корисник може да захтева приступ, исправку
          или брисање његових података о личности, може да ограничи обраду
          података, има право на приговор, има право да поднесе притужбу
          Поверенику за информације од јавног значаја и заштиту података о
          личности као и друга права из Закона о заштити података о личности.
        </p>
        <p className="text-padding">
          <strong>Где се обрађују подаци?</strong>
        </p>
        <p className="text-padding">
          Подаци се обрађују у оперативним канцеларијама Руковаоца и на било
          којим другим местима у којима се налазе стране које су укључене у
          обраду.
        </p>
        <p className="text-padding">
          <strong>Колико дуго се чувају подаци?</strong>
        </p>
        <p className="text-padding">
          Подаци о личности чуваће се не дуже од једне године од дана завршетка
          пројекта – Картица погодности „За наше хероје”.
          <br />
          <br />
          Руковаоцу се може дозволити да задржи податке о личности на дужи
          период онда кад је корисник дао сагласност за такву обраду, све док се
          таква сагласност не повуче. Штавише, Руковалац може бити у обавези да
          чува податке о личности дуже време кад год је то потребно ради
          извршавања законске обавезе или по налогу надлежног органа.
          <br />
          Након истека рока задржавања, подаци о личности се бришу. Сходно томе,
          право на приступ, право на брисање, право на исправку не могу се
          остварити након истека рока задржавања.
        </p>
        <p className="text-padding">
          <strong>Да ли постоји могућност промене политике приватности?</strong>
        </p>
        <p className="text-padding">
          Руковалац задржава право да у било које време измени ову Политику
          приватности тако што ће обавестити своје кориснике путем ове странице
          и евентуално у оквиру ове апликације и / или - колико је то технички и
          правно могуће - слањем обавештења корисницима путем било којих контакт
          информација доступним Руковаоцу.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
