import "./Hero.scss";

const Hero = () => (
  <div className="hero">
    <div className="hero__content">
      <h3 className="hero__subtitle">Програм подршке</h3>
      <h1 className="hero__title">За наше хероје</h1>
      <p className="hero__text">
        Наши лекари, медицинско особље и волонтери, сви ангажовани на првој
        линији одбране од COVID-19 су данас више него икад наши хероји.
        Заслужили су наше поштовање, захвалност и солидарност. Време је да им то
        покажемо на делу.
      </p>
      <p className="hero__text">
        Зато покрећемо програм подршке „За наше хероје“, кроз персонализоване
        картице погодности „Наш херој“, намењене свима који раде или волонтирају
        у COVID-19 систему.
      </p>
      <p className="hero__text">
        Kартица погодности „Наш херој” омогућава попусте и погодности приликом
        плаћања робе и услуга код бројних компанија и предузетника који
        учествују у програму, као и предност при остваривању права и добијању
        услуга у државним институцијама, јавним предузећима, локалним
        самоуправама и установама социјалне заштите.
      </p>
      <p>
        <strong>Подржимо оне који чувају наше здравље и животе !</strong>
      </p>
    </div>
  </div>
);

export default Hero;
