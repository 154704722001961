import { useState, useEffect } from "react";
import { getPartnersPaginated } from "../api";
import axios from "axios";

export default function usePartners() {
  const [loading, setLoading] = useState(true);
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    setLoading(true);

    getPartnersPaginated({
      shouldRandomize: true,
      cancelToken: source.token,
    })
      .then((res) => {
        setPartners((prevPartners) => {
          return [...prevPartners, ...res.data.list];
        });
        setLoading(false);
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return;
        }
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {
      source.cancel();
    };
  }, []);

  return { partners, loading };
}
