import "./Sidebar.scss";
import { NavLink, Link } from "react-router-dom";
import { ReactComponent as CloseMenu } from "./img/close.svg";
import { ReactComponent as MenuIcon } from "./img/burger.svg";
import logo from "./img/logoheroji250.png";
import { useState } from "react";

const Sidebar = ({ user }) => {
  const [click, setClick] = useState(false);

  const handleClick = () => {
    setClick(!click);
  };
  const closeMobileMenu = () => setClick(false);

  return (
    <nav className={`nav ${click ? "nav-active" : "nav-closed"}`}>
      <div className="nav-header">
        <NavLink to="/" onClick={closeMobileMenu}>
          <img src={logo} alt="Logo" className="logo" />
        </NavLink>

        <div className="mobile-menu" onClick={handleClick}>
          {click ? (
            <CloseMenu className="menu-icon" />
          ) : (
            <MenuIcon className="menu-icon" />
          )}
        </div>
      </div>
      <ul className="nav-items">
        <NavLink
          to="/kartica-pogodnosti"
          exact
          activeClassName="active-link"
          className="nav-item"
          onClick={closeMobileMenu}
        >
          <li>Картица погодности</li>
        </NavLink>
        {!user && (
          <>
            <NavLink
              to="/prijavite-se"
              exact
              activeClassName="active-link"
              className="nav-item"
              onClick={closeMobileMenu}
            >
              <li>Пријавите се</li>
            </NavLink>
            <NavLink
              to="/postanite-partner"
              exact
              activeClassName="active-link"
              className="nav-item"
              onClick={closeMobileMenu}
            >
              <li>Постаните партнер</li>
            </NavLink>
            <NavLink
              to="/mobilnа-aplikacijа"
              exact
              activeClassName="active-link"
              className="nav-item"
              onClick={closeMobileMenu}
            >
              <li>Мобилна апликација</li>
            </NavLink>
          </>
        )}
        {user && (
          <>
            <NavLink
              to="/partneri"
              activeClassName="active-link"
              onClick={closeMobileMenu}
            >
              <li>Партнери</li>
            </NavLink>
            <NavLink
              to="/popusti"
              activeClassName="active-link"
              onClick={closeMobileMenu}
            >
              <li>Специјални попусти</li>
            </NavLink>
            <NavLink
              to="/roba"
              activeClassName="active-link"
              onClick={closeMobileMenu}
            >
              <li>Роба</li>
            </NavLink>
            <NavLink
              to="/usluge"
              activeClassName="active-link"
              onClick={closeMobileMenu}
            >
              <li>Услуге</li>
            </NavLink>
            <NavLink
              to="/javne-usluge"
              activeClassName="active-link"
              onClick={closeMobileMenu}
            >
              <li>Јавне услуге</li>
            </NavLink>

            <NavLink
              to="/kontakt"
              activeClassName="active-link"
              onClick={closeMobileMenu}
            >
              <li>Контакт</li>
            </NavLink>
          </>
        )}
        {user ? (
          <>
            <NavLink
              to="/faq"
              exact
              activeClassName="active-link"
              className="nav-item"
              onClick={closeMobileMenu}
            >
              <li>Честа питања</li>
            </NavLink>
            <NavLink
              to="/privacy-policy"
              exact
              activeClassName="active-link"
              className="nav-item"
              onClick={closeMobileMenu}
            >
              <li>Политика приватности</li>
            </NavLink>
            <NavLink
              to="/profil"
              activeClassName="active-link"
              onClick={closeMobileMenu}
            >
              <li>Херој, {user.name}</li>
            </NavLink>
            <Link to="/logout">
              <li>Излогуј се</li>
            </Link>
          </>
        ) : (
          <>
            <NavLink
              to="/faq"
              exact
              activeClassName="active-link"
              className="nav-item"
              onClick={closeMobileMenu}
            >
              <li>Честа питања</li>
            </NavLink>
            <NavLink
              to="/privacy-policy"
              exact
              activeClassName="active-link"
              className="nav-item"
              onClick={closeMobileMenu}
            >
              <li>Политика приватности</li>
            </NavLink>
            <NavLink
              to="/login"
              activeClassName="active-link"
              onClick={closeMobileMenu}
            >
              <li>Улогуј се</li>
            </NavLink>
          </>
        )}
        <div className="sidebar__contact">
          <div className="sidebar__contact-group">
            <p className="sidebar__contact-title">Позовите нас</p>
            <p className="sidebar__contact-content">
              <a href="tel:0800252252">0800 252 252</a>
            </p>
          </div>
          <div className="sidebar__contact-group">
            <p className="sidebar__contact-title">Пишите нам</p>
            <p className="sidebar__contact-content">
              <a href="mailto:heroj@nasheroj.gov.rs">@ЗаНашеХероје</a>
            </p>
          </div>
        </div>
      </ul>
    </nav>
  );
};

export default Sidebar;
