import { useState, useEffect } from "react";
import { getLocationsForPartner } from "./api";
import Location from "./Location";
import "./LocationsTab.scss";

const LocationsTab = ({ id, long, lat, zoom }) => {
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getLocationsForPartner(id)
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        setLocations(res);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, [id]);

  return (
    <>
      {loading ? (
        <div className="loading">Учитава се...</div>
      ) : (
        <>
          {/* <div className="locations-map">
            <a
              href={`http://maps.google.com/?q=${long},${lat}&zoom=${zoom}`}
              target="_blank"
              rel="noopener noreferrer"
              className="locations-button"
            >
              Прикажи на мапи
            </a>
          </div> */}
          <div className="locations">
            {locations.map((location) => (
              <Location key={location.id} {...location} />
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default LocationsTab;
