import { useState, useRef, useEffect } from "react";
import "./Profile.scss";
import { getCurrentUser } from "./auth";
import { getProfile, changePassword } from "./api";
import Card from "./Card";

const Profile = () => {
  const user = getCurrentUser();
  const [profile, setProfile] = useState([]);
  const [loading, setLoading] = useState(false);
  const { stamp } = user;
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const oldPassword = useRef();
  const newPassword = useRef();

  useEffect(() => {
    setLoading(true);
    getProfile(stamp)
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        setProfile(res);
      })
      .catch((err) => {
        console.log(err);
        setError("Неуспешна промена лозинке");
      })
      .finally(() => setLoading(false));
  }, [stamp]);

  const handleChangePassword = (e) => {
    e.preventDefault();
    if (
      oldPassword.current.value.length === 0 ||
      newPassword.current.value.length === 0
    ) {
      setError("Морате унети обе лозинке");
      return;
    } else if (oldPassword.current.value === newPassword.current.value) {
      setError("Изабрали се исту лозинку");
      return;
    } else if (newPassword.current.value.length < 5) {
      setError("Нова лозинка мора имати 5 или више карактера");
      return;
    }
    setError(null);
    console.log(stamp, oldPassword.current.value, newPassword.current.value);
    changePassword(
      stamp,
      oldPassword.current.value,
      newPassword.current.value,
      newPassword.current.value
    )
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          setError("Неуспешна промена лозинке");
          throw Error(`Request rejected with status ${res.status}`);
        }
      })
      .then((res) => {
        console.log("res success json", res);
        oldPassword.current.value = "";
        newPassword.current.value = "";
        setSuccess(`Успешно сте променили лозинку`);
      })
      .catch((err) => console.log("err", err));
  };

  const handleChange = (e) => {
    setError(null);
    setSuccess(null);
  };

  return (
    <div className="container background-image">
      <h1 className="title-main title-padding">Профил хероја</h1>
      <div className="profile">
        {loading ? (
          <div className="loading">Учитава се...</div>
        ) : (
          <div className="profile__user">
            <div className="profile__card">
              <Card name={profile.name} number={profile.number} />
            </div>
            <span className="profile__username">{profile.name}</span>
            <br />
            <p>{profile.number}</p>
            <p>{profile.email}</p>
            <p>{profile.phoneNumber}</p>
          </div>
        )}
        <div className="profile__password">
          <form className="form" onSubmit={handleChangePassword}>
            {error && <div className="error">{error}</div>}
            {success && <div className="success">{success}</div>}
            <div className="form-group">
              <input
                type="password"
                name="oldpassword"
                id="oldpassword"
                className="input-field"
                placeholder="Стара лозинка"
                ref={oldPassword}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                name="newpassword"
                id="newpassword"
                className="input-field"
                placeholder="Нова лозинка"
                ref={newPassword}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <input
                type="submit"
                name="submit"
                className="input-submit"
                value="Промени лозинку"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Profile;
