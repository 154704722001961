import { format } from "date-fns";
import { baseUrl } from "./api";
import "./Discount.scss";

const Discount = ({
  id,
  name,
  startDate,
  endDate,
  description,
  leafletImage,
  image,
}) => {
  let dateFormat = "dd.MM.yyyy";
  let formattedStart, formattedEnd;
  if (startDate) {
    let start = Date.parse(startDate);
    formattedStart = format(start, dateFormat);
  }
  if (endDate) {
    let end = Date.parse(endDate);
    formattedEnd = format(end, dateFormat);
  }

  return (
    <div className="discount">
      <h3 className="discount__name">{name}</h3>
      <div className="discount__duration">
        Рок трајања:{" "}
        {startDate && (
          <span classname="discount__duration-start">{formattedStart}</span>
        )}{" "}
        -{" "}
        {endDate && (
          <span classname="discount__duration-start">{formattedEnd}</span>
        )}
      </div>

      {image && leafletImage ? (
        <div className="discount__images">
          <a
            href={`${baseUrl}/Action/${id}/img/2/${leafletImage}`}
            target="_blank"
            rel="noopener noreferrer"
            className="discount__leaflet"
          >
            <img
              src={`${baseUrl}/Action/${id}/img/1/${image}`}
              alt="Постер"
              className="discount__leaflet-image"
            />
          </a>
        </div>
      ) : (
        image && (
          <div className="discount__images">
            <img
              src={`${baseUrl}/Action/${id}/img/1/${image}`}
              alt="Постер"
              className="discount__leaflet-image"
            />
          </div>
        )
      )}
      <p className="discount__text">{description}</p>
    </div>
  );
};

export default Discount;
