import ScrollToTopOnMount from "../ScrollToTopOnMount";
import "./PageDefault.scss";

const BecomePartner = () => {
  return (
    <div className="container">
      <ScrollToTopOnMount />
      <h3 className="hero__subtitle">Укључите се у програм подршке</h3>
      <h1 className="hero__title">За наше хероје</h1>
      <div className="content">
        <p className="text-padding">
          Позивамо Вас да се као партнер укључите у програм подршке „За наше
          хероје“ како бисмо здравственим радницима, волонтерима и свима који су
          ангажовани у COVID-19 систему исказали поштовање и захвалност за
          њихову свакодневну посвећеност очувању нашег здравља и наших живота.
        </p>
        <p className="text-padding">
          <strong>
            Они су данас наши хероји више него икада. Време је да им захвалност
            покажемо на делу, пружањем подршке кроз уштеду времена и новца.
          </strong>
        </p>
        <p className="text-padding">
          <strong>
            Постаните партнер програма подршке „За наше хероје“ пружањем услуга
            погодности и попуста приликом плаћања робе и услуга у складу са
            вашим могућностима и на начин прилагођен вашој делатности и
            пословању.
          </strong>
        </p>
        <p>
          Прикључите се партнерском пулу „За наше хероје“ потписивањем Протокола
          о сарадњи са Привредном комором Србије или Генералним секретаријатом
          Владе. Потписивањем протокола о сарадњи постајете Партнер програма без
          обзира на величину, делатност и обим услуга који пружате.
        </p>
        <p>
          Ваша подршка и учешће биће промовисани кроз све видове комуникационе
          кампање намењене кориснисцима, али и широј јавности са фокусом на сајт
          и апликацију „Наш херој“: истицањем логотипа, услуга и прозвода (кроз
          текстове, фотографије, банере…).
        </p>
        <p className="text-padding">
          Прикључивањем програму стичете неграничено право коришћења визуелног
          идентитета програма подршке „За наше хероје“ у свим формама маркетинга
          и комуникације, као и истицање партнерства коришћењем облика
          комуникације:
        </p>
        <ul className="list text-padding">
          <li>Партнер програма „За наше хероје“</li>
          <li>Партнер картице погодности „Наш херој“</li>
        </ul>
        <p className="text-padding">
          Програм подршке „За наше хероје“ кроз персонализоване картице
          погодности „Наш херој“, покренулa je Влада Републике Србије („Службени
          гласник РС”, број 146/20; 05 број 33-9862/2020-1).
          <br /> Програм подршке „За наше хероје“ реализује Министарство здравља
          РС у сарадњи са Генералним секретаријатом Владе, Народном банком
          Србије, Привредном комором Србије и јавним установама, уз подршку
          Програма Уједињених нација за развој (УНДП).Мере и активности везане
          за картицу погодности „Наш херој“ биће привременог карактера, односно
          орочене су трајањем пандемије болести COVID-19 изазване вирусом
          SARS-CoV-2.
        </p>
        <h3 className="last-line">Да хероји знају да и за њих неко брине!</h3>
      </div>
    </div>
  );
};

export default BecomePartner;
