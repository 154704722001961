import ScrollToTopOnMount from "../ScrollToTopOnMount";
import Hero from "../BenefitCardPage/components/Hero";
import InfoLinks from "../BenefitCardPage/components/InfoLinks";
import HeroCard from "../BenefitCardPage/components/HeroCard";
import ForOurHeroes from "../BenefitCardPage/components/ForOurHeroes";
import RandomPartners from "../BenefitCardPage/components/RandomPartners";

const WelcomePage = ({ user }) => (
  <>
    <ScrollToTopOnMount />
    <Hero />
    <InfoLinks />
    <HeroCard />
    <ForOurHeroes />
    <RandomPartners user={user} />
  </>
);

export default WelcomePage;
