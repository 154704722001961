import axios from "axios";
export const baseUrl = process.env.REACT_APP_WEB_API_URL;
export const apiKey = process.env.REACT_APP_API_KEY;

export const login = (username, password) => {
  const data = { username, password };
  return fetch(`${baseUrl}/Hero/Login`, {
    method: "POST",
    headers: {
      "X-API-KEY": apiKey,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
};

export const getProfile = (stamp) => {
  return fetch(`${baseUrl}/Hero/${stamp}`, {
    method: "GET",
    headers: {
      "X-API-KEY": apiKey,
      "Content-Type": "application/json",
    },
  });
};

export const forgotPassword = (email) => {
  return fetch(`${baseUrl}/Hero/ForgotPassword`, {
    method: "POST",
    headers: {
      "X-API-KEY": apiKey,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email }),
  });
};

export const changePassword = (
  stamp,
  currentPassword,
  newPassword,
  confirmPassword
) => {
  const data = { stamp, currentPassword, newPassword, confirmPassword };
  return fetch(`${baseUrl}/Hero/ChangePassword`, {
    method: "POST",
    headers: {
      "X-API-KEY": apiKey,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
};

export const sendMessage = (userStamp, content, alternativeEmail) => {
  const data = { userStamp, content, alternativeEmail };
  return fetch(`${baseUrl}/Message/Create`, {
    method: "POST",
    headers: {
      "X-API-KEY": apiKey,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
};

export const getPartnerById = (id) => {
  return fetch(`${baseUrl}/Partner/Get/${id}`, {
    method: "GET",
    headers: {
      "X-API-KEY": apiKey,
      "Content-Type": "application/json",
    },
  });
};

export const getCategoryById = (id) => {
  return fetch(`${baseUrl}/Category/Get/${id}`, {
    method: "GET",
    headers: {
      "X-API-KEY": apiKey,
      "Content-Type": "application/json",
    },
  });
};

export const getPartners = (id) => {
  return fetch(`${baseUrl}/Partner/GetList`, {
    method: "GET",
    headers: {
      "X-API-KEY": apiKey,
      "Content-Type": "application/json",
    },
  });
};

export const getPartnersPaginated = ({
  query,
  pageNumber,
  pageSize = 12,
  shouldRandomize,
  cancelToken,
}) => {
  return axios({
    method: "GET",
    url: `${baseUrl}/Partner/GetList`,
    params: {
      Name: query,
      Page: pageNumber,
      SizePerPage: pageSize,
      shouldRandomize,
    },
    headers: {
      "X-API-KEY": apiKey,
      "Content-Type": "application/json",
    },
    cancelToken,
  });
};

export const getPartnerByCategory = (id) => {
  return fetch(`${baseUrl}/Partner/GetByCategory/${id}`, {
    method: "GET",
    headers: {
      "X-API-KEY": apiKey,
      "Content-Type": "application/json",
    },
  });
};

export const getRandomPartners = (count = 12) => {
  return fetch(`${baseUrl}/Partner/GetRandom?count=${count}`, {
    method: "GET",
    headers: {
      "X-API-KEY": apiKey,
      "Content-Type": "application/json",
    },
  });
};

export const getActiveDiscounts = (number = 20) => {
  return fetch(`${baseUrl}/Action/GetActive?count=${number}`, {
    method: "GET",
    headers: {
      "X-API-KEY": apiKey,
      "Content-Type": "application/json",
    },
  });
};

export const getDiscountsForPartner = (id) => {
  return fetch(`${baseUrl}/Action/GetAll?partnerId=${id}`, {
    method: "GET",
    headers: {
      "X-API-KEY": apiKey,
      "Content-Type": "application/json",
    },
  });
};

export const getLocationsForPartner = (id) => {
  return fetch(`${baseUrl}/Location/GetAll?partnerId=${id}`, {
    method: "GET",
    headers: {
      "X-API-KEY": apiKey,
      "Content-Type": "application/json",
    },
  });
};
