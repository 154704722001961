import { useState, useEffect, useRef } from "react";
import { baseUrl, apiKey } from "./api";
import { Link, useRouteMatch } from "react-router-dom";
import { transliterateToLat } from "./transliterate";
import "./Category.scss";

const Category = ({ id, name }) => {
  const [category, setCategory] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const match = useRouteMatch();
  const searchRef = useRef();

  useEffect(() => {
    setLoading(true);
    fetch(`${baseUrl}/Category/GetByType/${id}`, {
      method: "GET",
      headers: {
        "X-API-KEY": apiKey,
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        setCategory(res);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, [id]);

  useEffect(() => {
    const results = category.filter(
      (c) =>
        c.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        transliterateToLat(c.name.toLowerCase()).includes(
          transliterateToLat(searchTerm.toLowerCase())
        )
    );
    setSearchResults(results);
  }, [searchTerm, category]);

  useEffect(() => {
    searchRef.current.focus();
  }, []);

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className="container">
      <div className="category__header">
        <h2 className="title-main">{name} (по категоријама)</h2>
        <input
          ref={searchRef}
          type="text"
          onChange={handleChange}
          placeholder="Претражи"
          className="category__search"
        />
      </div>
      <div className="category-list">
        {loading ? (
          <div className="loading">Учитава се...</div>
        ) : (
          searchResults.map((category) => (
            <Link
              key={category.id}
              to={`${match.url}/${category.id}`}
              className="category"
            >
              <div
                className="category-color"
                style={{
                  backgroundColor: `#${category.color}`,
                }}
              ></div>
              <h4 className="category__title">{category.name}</h4>
            </Link>
          ))
        )}
      </div>
    </div>
  );
};

export default Category;
