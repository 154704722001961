import ScrollToTopOnMount from "../ScrollToTopOnMount";
import "./PageDefault.scss";

const Faq = () => {
  return (
    <div className="container">
      <ScrollToTopOnMount />
      <h1 className="page__title title-padding">Честа питања</h1>
      <div className="content">
        <p className="text-padding">
          <strong>Ко има право на картицу „Наш херој“?</strong>
        </p>
        <p className="text-padding email">
          Сви медицински и немедицински радници, запослени, радно ангажовани и
          волонтери у COVID-19 систему су данас више неко икад наши хероји и
          имају право на картицу погодности „Наш херој“.
        </p>
        <p className="text-padding">
          <strong>Како да се пријавимо за картицу?</strong>
        </p>
        <p className="text-padding">
          Потребно је да се обратите одговорном лицу за спровођење пројекта
          Картица погодности „За наше хероје“ у Вашој установи. Захтев за
          добијање картице погодности „Наш херој“ можете преузети са интернет
          презентације{" "}
          <a href="https://nasheroj.gov.rs/prijavite-se">
            https://nasheroj.gov.rs/prijavite-se
          </a>
          , или од одговорног лица у Вашој установи. Захтев треба попунити и
          предати одговорном лицу.
        </p>
        <p className="text-padding">
          <strong>Ко је одговорно лице?</strong>
        </p>
        <p className="text-padding">
          Одговорно лице је Ваш колега/колегиница које је именовано од стране
          Ваше установе задужено за пројекат Картица погодности „За наше
          хероје“.
        </p>
        <p className="text-padding">
          <strong>
            Да ли је адреса електронске поште услов за добијање картице
            погодности „Наш херој“?
          </strong>
        </p>
        <p className="text-padding">
          Није неопходно имати адресу електронске поште, али је пожељно. Адреса
          електронске поште биће корисна за пријављивање на интернет
          презентацију и апликацију „Наш херој“, где ће носиоци картице моћи да
          се детаљно информишу о свим погодностима и специјалним попустима.
        </p>
        <p className="text-padding">
          <strong>
            Ко су све партнери пројекта Картица погодности „За наше хероје“?
          </strong>
        </p>
        <p className="text-padding">
          Списак партнера пројекта се ажурира свакодневно и активно радимо на
          томе да партнера пројекта буде што више. Информације о нашим
          партнерима можете наћи на интернет презентацији{" "}
          <a href="https://nasheroj.gov.rs/">https://nasheroj.gov.rs/</a>, у
          одељку Наши партнери.
        </p>
        <p className="text-padding">
          <strong>Шта све доноси картица погодности „Наш херој“?</strong>
        </p>
        <p className="text-padding">
          Нашим херојима намењене су погодности и специјални попусти које нуде
          парнтери пројекта Картица погодности „За наше хероје“. Прецизне
          информације о понуди могу се сазнати пријављивањем на сајт{" "}
          <a href="https://nasheroj.gov.rs/">https://nasheroj.gov.rs/</a> и
          преко мобилне апликације „Наш херој“. Хероји се пријављују на сајт и
          апликацију уношењем корисничког имена, које је адреса електронске
          поште коју су доставили у Захтеву, и шифре, која је јединствени
          идентификациони број картице сваког хероја.
        </p>
        <p className="text-padding">
          <strong>
            Да ли постоје трошкови у вези са картицом погодности „Наш херој“?
          </strong>
        </p>
        <p className="text-padding">
          Картица погодности „Наш херој“, намењена запосленима, волонтерима и
          радно ангажованима у COVID-19 систему, потпуно је бесплатна и важи на
          целој територији Републике Србије. Не постоје трошкови чланарине ни
          одржавања картице „Наш херој“.
        </p>
      </div>
    </div>
  );
};

export default Faq;
