import { useState, useEffect } from "react";
import { NavLink, Route, useLocation, Switch } from "react-router-dom";
import { baseUrl, getPartnerById } from "./api";
import BenefitsTab from "./BenefitsTab";
import DiscountsTab from "./DiscountsTab";
import WorkAreaTab from "./WorkAreaTab";
import LocationsTab from "./LocationsTab";
import notAvailable from "./img/not_available.png";
import "./Partner.scss";

const Partner = ({ match }) => {
  const [partner, setPartner] = useState({});
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  let actionId;

  if (location.state) {
    actionId = location.state.actionId;
  }
  const { id } = match.params;
  // console.log("location", location);
  // console.log(actionId);

  useEffect(() => {
    setLoading(true);
    getPartnerById(id)
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        setPartner(res);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, [id]);

  return (
    <div className="container">
      {loading ? (
        <div className="loading">Учитава се...</div>
      ) : (
        <div>
          <div className="partner-header">
            <div>
              <h1 className="title-main">{partner.name}</h1>
              <p className="partner-header-description">
                {partner.description}
              </p>
            </div>
            <div>
              <img
                src={
                  partner.logoImage
                    ? `${baseUrl}/Partner/${partner.id}/img/1/${partner.logoImage}`
                    : notAvailable
                }
                alt="Logo"
                className="partner-header-image"
              />
            </div>
          </div>

          {partner ? (
            <div className="tabs">
              <div className="tab-header">
                <NavLink
                  to={`${match.url}/pogodnosti`}
                  className="tab"
                  activeClassName="active-tab"
                >
                  Погодности
                </NavLink>
                <NavLink
                  to={`${match.url}/popusti`}
                  className="tab"
                  activeClassName="active-tab"
                >
                  Специјални попусти
                </NavLink>
                <NavLink
                  to={`${match.url}/delatnost`}
                  className="tab"
                  activeClassName="active-tab"
                >
                  Делатност
                </NavLink>
                <NavLink
                  to={`${match.url}/lokacije`}
                  className="tab"
                  activeClassName="active-tab"
                >
                  Локације
                </NavLink>
              </div>

              <div className="tab-content">
                <Switch>
                  <Route
                    path={`${match.path}/delatnost`}
                    render={(props) =>
                      partner ? (
                        <WorkAreaTab {...props} business={partner.business} />
                      ) : null
                    }
                  />
                  <Route
                    path={`${match.path}/lokacije`}
                    render={(props) => (
                      <LocationsTab
                        {...props}
                        id={id}
                        long={partner.locationX}
                        lat={partner.locationY}
                        zoom={partner.zoom}
                      />
                    )}
                  />
                  <Route
                    path={`${match.path}/popusti`}
                    render={(props) => (
                      <DiscountsTab {...props} id={id} actionId={actionId} />
                    )}
                  />
                  <Route
                    // path={`${match.path}/pogodnosti`}
                    render={(props) => (
                      <BenefitsTab {...props} partner={partner} />
                    )}
                  />
                  {/* <Redirect to={`${match.url}/pogodnosti`} /> */}
                </Switch>
              </div>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default Partner;
