import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ActionCard from "./ActionCard";
import { getActiveDiscounts } from "./api";
import "./PartnerList.scss";

const ActionList = () => {
  const [actions, setActions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getActiveDiscounts()
      .then((res) => res.json())
      .then((res) => {
        setLoading(true);
        setActions(res);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  return (
    <div className="container">
      <h1 className="title-main title-padding">Специјални попусти</h1>
      <div className="partner-list">
        {actions.map((action) => (
          <Link
            // to={`/partneri/${action.partnerId}/popusti`}
            to={{
              pathname: `/partneri/${action.partnerId}/popusti`,
              state: {
                actionId: action.id,
              },
            }}
            key={action.id}
            className="partner"
          >
            <ActionCard action={action} />
          </Link>
        ))}
      </div>
      {loading && <div className="loading">Учитава се...</div>}
    </div>
  );
};

export default ActionList;
