import "./Contact.scss";
import { useState, useRef } from "react";
import { sendMessage } from "./api";
import { getCurrentUser } from "./auth";
import { ImPhone } from "react-icons/im";
import { isEmail } from "./utils";

const Contact = () => {
  const user = getCurrentUser();
  const { stamp, email } = user;
  const [error, setError] = useState();
  const [errorEmail, setErrorEmail] = useState();
  const [success, setSuccess] = useState();
  const emailRef = useRef();
  const messageRef = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();

    let isValdiEmail = isEmail(emailRef.current.value);
    console.log(emailRef.current.value);
    console.log(isValdiEmail);

    if (messageRef.current.value.length === 0) {
      setError("Морате унети поруку");
      return;
    }

    if (!isValdiEmail) {
      setErrorEmail("Морате унети исправну адресу");
      return;
    }

    sendMessage(stamp, messageRef.current.value, emailRef.current.value)
      .then((res) => {
        if (res.status === 200) {
          setSuccess("Успешно сте послали поруку");
          return res.json();
        } else {
          setError("Неуспешно послата порука");
          throw Error(`Request rejected with status ${res.status}`);
        }
      })
      .then((res) => {
        console.log("res success json", res);
        messageRef.current.value = "";
      })
      .catch((err) => console.log("err", err));
  };

  const handleChange = (e) => {
    setError(null);
    setSuccess(null);
  };

  const handleChangeEmail = (e) => {
    setErrorEmail(null);
  };

  return (
    <div className="container contact-background">
      <h1 className="title-main title-padding">Контактирајте нас</h1>
      <div className="contact">
        <div className="contact__info">
          Позовите нас на
          <br />
          <ImPhone color={"#036493"} />{" "}
          <span className="contact__phone">
            <a href="tel:0800252252">0800 252 252</a>
          </span>
          <p>
            позив је бесплатан за све позиве из мобилне и фиксне телефоније у
            Републици Србији
          </p>
          <br />
          или нам пошаљите поруку
        </div>
      </div>
      <div className="contact-form">
        <form className="form" onSubmit={handleSubmit} noValidate>
          <p className="form__disclaimer">
            *Опционо можете променити адресу за контакт
          </p>
          {errorEmail && <div className="error">{errorEmail}</div>}
          <div className="form-group">
            <input
              type="email"
              name="email"
              id="email"
              className="input-field"
              placeholder="Електронска пошта"
              defaultValue={email}
              ref={emailRef}
              onChange={handleChangeEmail}
            />
          </div>
          {error && <div className="error">{error}</div>}
          {success && <div className="success">{success}</div>}
          <div className="form-group">
            <textarea
              cols="40"
              rows="8"
              name="message"
              className="input-field"
              placeholder="Порука"
              ref={messageRef}
              onChange={handleChange}
            ></textarea>
          </div>
          <div className="form-group">
            <input
              type="submit"
              name="submit"
              className="input-submit"
              value="Пошаљи поруку"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
