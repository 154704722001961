import styles from "./PartnerCard.module.scss";
import { baseUrl } from "./api";
import notAvailable from "./img/not_available.png";

const PartnerCard = ({ partner }) => {
  return (
    <>
      <div className={styles["partner-image"]}>
        <img
          src={
            partner.logoImage
              ? `${baseUrl}/Partner/${partner.id}/img/1/${partner.logoImage}`
              : notAvailable
          }
          alt="Logo"
        />
      </div>
      <h3 className={styles["partner-name"]}>{partner.name}</h3>
      <p className={styles["partner-description"]}>{partner.description}</p>
    </>
  );
};

export default PartnerCard;
