import { Link } from "react-router-dom";
import "./InfoLinks.scss";
import prijava from "../../img/prijava.jpg";
import partneri from "../../img/partneri.jpg";
import aplikacija from "../../img/aplikacija.jpg";

const InfoLinks = () => (
  <div className="card-info-group">
    <Link to="/prijavite-se" className="card-info">
      <div>
        <img className="card-img-top" src={prijava} alt="Card cap" />
        <div className="card-block">
          <h4 className="card-title">Пријавите се</h4>
          <p>
            Ако сте запослени, радно ангажовани или волонтирате у COVID-19
            систему, Вама је као израз захвалности и подршке намењена картица
            погодности „Наш херој“.{" "}
            <span className="card-block-detail">Детаљније...</span>
          </p>
        </div>
      </div>
    </Link>
    <Link to="/postanite-partner" className="card-info">
      <div>
        <img className="card-img-top" src={partneri} alt="Card cap" />
        <div className="card-block">
          <h4 className="card-title">Постаните партнер</h4>
          <p>
            Они су данас наши хероји више него икада. Време је да им захвалност
            покажемо на делу, пружањем подршке кроз уштеду времена и новца.{" "}
            <span className="card-block-detail">Детаљније...</span>
          </p>
        </div>
      </div>
    </Link>
    <Link to="/mobilnа-aplikacijа" className="card-info">
      <div>
        <img className="card-img-top" src={aplikacija} alt="Card cap" />
        <div className="card-block">
          <h4 className="card-title">Преузми апликацију</h4>
          <p>
            Апликација за мобилне уређаје Bам омогућава брз и једноставан
            преглед комплетне понуде свих партнерa програма „За наше хероје“.{" "}
            <span className="card-block-detail">Детаљније...</span>
          </p>
        </div>
      </div>
    </Link>
  </div>
);

export default InfoLinks;
