import { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { forgotPassword } from "./api";
import { isEmail } from "./utils";
import "./Login.scss";

const ForgottenPassword = () => {
  const usernameRef = useRef();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState();

  const handleForgottenPassword = (e) => {
    e.preventDefault();
    if (usernameRef.current.value.length === 0) {
      setError("Морате унети адресу електронске поште");
      return;
    }
    if (!isEmail(usernameRef.current.value)) {
      setError("Морате унети исправну адресу");
      return;
    }
    setError(null);
    forgotPassword(usernameRef.current.value)
      .then((res) => {
        if (res.status === 200) {
          setSuccess(
            `Послата вам је нова лозинка на адресу ${usernameRef.current.value}`
          );
          console.log(res);
          return res.json();
        } else {
          setError("Непостојећа адреса");
        }
      })
      .then((res) => {
        usernameRef.current.value = "";
        console.log(res);
      })
      .catch((err) => console.log(err));
  };

  const handleChange = (e) => {
    setError(null);
    setSuccess(null);
  };

  return (
    <main className="main">
      <div className="wrapper">
        <div className="card">
          <div className="title">
            <h1 className="title title-large">Заборављена лозинка</h1>
          </div>
          <form className="form" onSubmit={handleForgottenPassword} noValidate>
            {success && <div className="success">{success}</div>}
            {error && <div className="error">{error}</div>}
            <div className="form-group">
              <input
                type="email"
                name="email"
                id="email"
                className="input-field"
                placeholder="Електронска пошта"
                ref={usernameRef}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <input
                type="submit"
                name="submit"
                className="input-submit"
                value="Даље"
              />
              <Link to="login" className="linktext">
                Улогуј се
              </Link>
            </div>
          </form>
        </div>
      </div>
    </main>
  );
};

export default ForgottenPassword;
