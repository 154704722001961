import { Link } from "react-router-dom";
import ScrollToTopOnMount from "../ScrollToTopOnMount";
import "./PageDefault.scss";
import zahtevPdf from "../assets/Zahtev za dobijanje kartice pogodnosti Nas heroj.pdf";
import usloviPdf from "../assets/Uslovi koriscenja kartice pogodnosti Nas heroj.pdf";
import obavestenjePdf from "../assets/Obavestenje o obradi podataka o licnosti.pdf";

const ApplyForCard = () => {
  return (
    <div className="container">
      <ScrollToTopOnMount />
      <h1 className="page__title">Пријавите се</h1>
      <h3 className="page__subtitle title-padding negative-margin">
        за картицу погодности „Наш херој“
      </h3>
      <div className="content">
        <p className="text-padding">
          Ако сте запослени, радно ангажовани или волонтирате у COVID-19
          систему, Вама је као израз захвалности и подршке намењена картица
          погодности „Наш херој“. Потребно је да Ваша установа именује одговорно
          лице које ће бити задужено за спровођење овог пројекта. Пријаве за
          картицу можете поднети лично овлашћеном лицу у Вашој установи. Пријава
          обухвата следећа документа:
        </p>
        <div className="links-container">
          <div className="download download__padded">
            <a href={zahtevPdf} className="download__link">
              Захтев за добијање картице погодности
            </a>
          </div>

          <div className="download download__padded">
            <a href={usloviPdf} className="download__link">
              Услови коришћења картице погодности
            </a>
          </div>
          <div className="download download__padded">
            <a href={obavestenjePdf} className="download__link">
              Обавештење о обради података о личности
            </a>
          </div>
        </div>

        <p className="text-padding">
          Наведена документа и обрасце за пријављивaње, такође, можете преузети
          и код одговорног лица за евиденцију запослених и волонтера у вашој
          COVID-19 јединици.
        </p>
        <p className="text-padding">
          Након подношења пријаве, врши се израда картица. Свака картица је
          персонализована, односно садржи свој идентификациони (ИД) број који не
          открива ни један податак о личности. Након персонализације картице ИД
          број постаје активан, а самим тим стичу се услови за активацију и
          коришћење картице.
        </p>
        <p>
          Персонализовану картицу можете преузети у овлашћеној административној
          служби у вашој COVID-19 јединици, односно у јединици у којој сте
          поднели пријаву.
        </p>
        <p>
          За све додатне информације о начину пријављивања и преузимања Ваше
          картице можете да се обратите овлашћеној административној служби у
          Вашој COVID-19 јединици.
        </p>
        <p className="text-padding">
          После пријављивања и добијања картице моћи ћете да се региструјете на
          сајту или преузмете апликацију за мобилне уређаје, путем које ћете се
          информисати о погодностима. Упутство за преузимање и пријављивање на
          апликацију „Наш херој” можете наћи на следећој страници{" "}
          <Link to="/mobilnа-aplikacijа">
            https://nasheroj.gov.rs/mobilna-aplikacija
          </Link>
          .
        </p>
      </div>
    </div>
  );
};

export default ApplyForCard;
