import "./Footer.scss";
import grb from "../src/img/vlada.png";
import pks from "../src/img/pks.png";
import undp from "../src/img/undp-logo.png";

const Footer = () => (
  <footer className="footer">
    <div className="footer__main">
      <div className="footer__sponsors">
        <a
          href="https://www.srbija.gov.rs/"
          target="_blank"
          rel="noreferrer"
          className="sponsor-link"
        >
          <img src={grb} alt="Грб Србије" />
        </a>
        <a
          href="https://pks.rs/"
          target="_blank"
          rel="noreferrer"
          className="sponsor-link"
        >
          <img src={pks} alt="Привредна комора Србије" />
        </a>
        <a
          href="https://www.rs.undp.org/"
          target="_blank"
          rel="noreferrer"
          className="sponsor-link"
        >
          <img src={undp} alt="United Nations Development Programme" />
        </a>
      </div>
      <div className="footer__contact">
        <h4 className="footer__contact-title">Контакт</h4>
        <p className="footer__contact-details">
          Информације за кориснике{" "}
          <strong>
            <a href="mailto:heroj@nasheroj.gov.rs">heroj@nasheroj.gov.rs</a>
          </strong>
        </p>
        <p className="footer__contact-details">
          Информације за партнере{" "}
          <strong>
            <a href="mailto:partner@nasheroj.gov.rs">partner@nasheroj.gov.rs</a>
          </strong>
        </p>
        <p className="footer__contact-details">
          Информације за новинаре{" "}
          <strong>
            <a href="mailto:press@nasheroj.gov.rs">press@nasheroj.gov.rs</a>
          </strong>
        </p>
      </div>
    </div>
    <div className="footer__disclaimer">
      Програм подршке „За наше хероје“ кроз персонализоване картице погодности
      „Наш херој“, покренулa je Влада Републике Србије („Службени гласник РС”,
      број 146/20; 05 број 33-9862/2020-1).
      <br />
      Програм подршке „За наше хероје“ реализује Министарство здравља РС у
      сарадњи са Генералним секретаријатом Владе, Народном банком Србије,
      Привредном комором Србије и јавним установама, уз подршку Програма
      Уједињених нација за развој (УНДП).Мере и активности везане за картицу
      погодности „Наш херој“ биће привременог карактера, односно орочене су
      трајањем пандемије болести COVID-19 изазване вирусом SARS-CoV-2.
    </div>
  </footer>
);

export default Footer;
