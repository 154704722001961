import { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { isEmail } from "./utils";
import "./Login.scss";

const Login = ({ onLogin, error, setError }) => {
  const usernameRef = useRef();
  const passwordRef = useRef();
  const [emailError, setEmailError] = useState(null);

  const handleLogin = (e) => {
    e.preventDefault();
    if (!isEmail(usernameRef.current.value)) {
      setEmailError("Морате унети исправну адресу");
      return;
    }
    onLogin(usernameRef.current.value, passwordRef.current.value);
  };

  const handleChange = (e) => {
    setError(null);
    setEmailError(null);
  };

  return (
    <main className="main">
      <div className="wrapper">
        <div className="card">
          <div className="title">
            <h1 className="title title-large">Пријава на систем</h1>
          </div>
          <form className="form" onSubmit={(e) => handleLogin(e)} noValidate>
            {emailError && <div className="error">{emailError}</div>}
            {error && <div className="error">{error}</div>}
            <div className="form-group">
              <input
                type="email"
                name="email"
                id="email"
                className="input-field"
                placeholder="Електронска пошта"
                ref={usernameRef}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                name="password"
                id="password"
                className="input-field"
                placeholder="Лозинка"
                ref={passwordRef}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <input
                type="submit"
                name="submit"
                className="input-submit"
                value="Улогуј се"
              />
              <Link to="zaboravljena-lozinka" className="linktext">
                Заборављена лозинка
              </Link>
            </div>
          </form>
        </div>
      </div>
    </main>
  );
};

export default Login;
