import { baseUrl } from "./api";
import styles from "./ActionCard.module.scss";
import notAvailable from "./img/not_available.png";

const ActionCard = ({ action }) => {
  return (
    <>
      <div className={styles["action-image"]}>
        <img
          src={
            action.image
              ? `${baseUrl}/Action/${action.id}/img/1/${action.image}`
              : notAvailable
          }
          alt="Logo"
        />
      </div>
      <h3 className={styles["action-name"]}>{action.name}</h3>
      <p className={styles["action-description"]}>
        {action.shortDescription?.substr(0, 100)}
      </p>
    </>
  );
};

export default ActionCard;
